<template>
  <v-popover
    v-if="hasHelper"
    trigger="hover click"
    placement="top-center"
    :autoHide="false"
    popoverInnerClass="popover-inner no-overflow"
  >
    <div @click.stop class="helper__icon helper__icon--info backoffice-icons">warning</div>
    <template slot="popover">
      <div class="helper__content">
        {{ helper }}
      </div>
    </template>
  </v-popover>
</template>

<script>
export default {
  name: 'UiHelper',
  props: {
    helper: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    hasHelper() {
      return this.helper && this.helper !== ''
    },
  },
}
</script>

<style lang="scss" scoped>
.helper {
  &__icon {
    margin-left: 3px;
    color: $orange-tree-poppy;
    font-size: $font-size-lg;

    &--info {
      cursor: pointer;
      color: $blue-royal;
    }
  }
  &__content {
    border: 1px solid $blue-royal;
    box-shadow: 3px 6px 8px rgba(0, 0, 0, 0.1);
    background-color: $blue-royal;
    padding: 7px 10px;
    max-width: 220px;
    color: rgb(255, 255, 255);
    font-size: calc(#{$font-size-sm} + 0.7px);
    font-weight: 400;
    hyphens: auto;
  }
}
</style>
