<template>
  <div class="skeleton-icon" :style="{ marginRight, marginLeft, width, height }"></div>
</template>

<script>
export default {
  name: 'SkeletonIcon',
  props: {
    marginRight: {
      type: String,
      required: false,
      default: '',
    },
    marginLeft: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      required: false,
      default: '24px',
    },
    height: {
      type: String,
      required: false,
      default: '24px',
    },
  },
}
</script>

<style lang="scss" scoped>
.skeleton-icon {
  @include skeleton-loader;

  border-radius: 50%;
}
</style>
