export const scroll = (view, element, initScrollTop) => {
  let scrollDirection = ''
  const currentScrollTop = view.scrollTop
  const offsetHeight = view.offsetHeight
  const scrollHeight = view.scrollHeight
  const currentOffsetTop = element.getBoundingClientRect().top

  if (currentScrollTop > initScrollTop) {
    scrollDirection = 'down'
  } else {
    scrollDirection = 'up'
  }

  return {
    scrollDirection,
    currentScrollTop: currentScrollTop <= 0 ? 0 : currentScrollTop,
    currentOffsetTop,
    offsetHeight,
    scrollHeight,
  }
}
