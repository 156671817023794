<template>
  <modal
    name="modal-user-express-location"
    :title="title"
    :has-apply="true"
    :apply-label="applyLabel"
    :is-updating="isUpdating"
    @save="save"
    @closed="closed"
  >
    <template v-slot:container>
      <template v-if="description">{{ description }}</template>
      <ui-tag
        v-if="parameters.mode === 'list'"
        v-model="modelData"
        id="modal-user-express-location-tag"
        :placeholder="label.placeholder || ''"
        :tag-label="label.tag"
        :show-label="true"
        :error="$v.modelData.$error"
        :label="parameters.dropdown && parameters.dropdown.label ? parameters.dropdown.label : null"
        :track-by="parameters.dropdown && parameters.dropdown.trackBy ? parameters.dropdown.trackBy : null"
        :options="tagOptions"
        :no-absolute="true"
        :custom-tag="parameters.dropdown ? parameters.dropdown.customTag : false"
        :is-required="parameters.required"
        :taggable="parameters.taggable"
        :searchable="searchable"
        :clear-on-select="false"
        @tag="addTag"
        @input="setTags"
        @search="onSearch"
      >
        <template v-slot:helper v-if="$v.modelData.$error && !$v.modelData.required">
          {{ $t('errors.required') }}
        </template>
        <template v-slot:helper v-else-if="$v.modelData.$error">
          {{ $t('errors.email') }}
        </template>
        <template v-slot:tag="{ tag }">
          <span class="ui-tag__multiselect__tag__label">{{ tag.group ? `[${tag.group}]` : '' }} {{ tag.name }}</span>
        </template>
        <template v-slot:option="{ option }">
          <span v-if="option.isTag">{{ option.label }}</span>
          <span v-else>{{ option.group ? `[${option.group}]` : '' }} {{ option.name }}</span>
        </template>
      </ui-tag>
    </template>
  </modal>
</template>

<script>
import UiTag from '@/components/UI/Tag.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ModalUserExpressLocation',
  components: {
    UiTag,
  },
  props: {
    applyLabel: {
      type: String,
      required: false,
      default: null,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Object],
      required: true,
    },
    objKey: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: [String, Object, Array, Number],
      required: false,
      default: '',
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        type: 'text',
        mode: 'input',
        required: false,
        options: [],
        listKey: '',
        number: {
          min: 0,
          max: 1,
          step: 1,
        },
        dropdown: {
          label: '',
          trackBy: '',
        },
      }),
    },
  },
  data() {
    return {
      modelData: '',
    }
  },
  mounted() {
    if (Array.isArray(this.data) || this.parameters.mode === 'list') {
      this.modelData = this.data ? [...this.data] : []
    }
  },
  computed: {
    tagOptions() {
      if (this.parameters.options?.length > 0) {
        return this.parameters.options
      }
      return []
    },
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.parameters.listKey) {
          this.$emit('save', {
            objKey: { [this.objKey]: this.modelData.map(data => ({ [this.parameters.listKey]: data })) },
          })
        } else {
          if (this.objKey === 'locationsMapping') {
            this.$emit('saveMapping', {
              objKey: { [this.objKey]: this.modelData },
            })
          } else {
            this.$emit('save', {
              objKey: { [this.objKey]: this.parameters.type === 'json' ? JSON.parse(this.modelData) : this.modelData },
            })
          }
        }
        this.$v.$reset()
      }
    },
    closed() {
      this.$emit('closed')
    },
    addTag(tag) {
      if (this.tagOptions.length === 0) {
        this.modelData.push(tag)
      }
    },
    setTags(tags) {
      this.$emit('tagsUpdate', tags)
      if (this.parameters.dropdown?.customTag) {
        this.modelData = tags.map(currentTags => currentTags.tag)
      } else {
        this.modelData = tags
      }
    },
    onSearch(value) {
      this.$emit('search', value)
    },
  },
  validations() {
    return {
      modelData: {
        required: this.parameters.required ? required : false,
      },
    }
  },
  beforeDestroy() {
    this.$emit('tagsUpdate', [])
  },
}
</script>
