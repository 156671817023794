<template>
  <screen-grid class="userexpressid-general">
    <screen-notification v-if="userExpressHasCampaign" :description="$t('userexpress.id.general.user.notification')" />
    <screen-card
      :title="$t('userexpress.id.general.user.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('userexpress.id.general.user.label.firstName')"
          icon="user"
          :has-data="typeof currentUserExpress.firstName === 'string'"
          :data="currentUserExpress.firstName"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('firstName', 'text', 'input', true)"
        />
        <screen-block
          :title="$t('userexpress.id.general.user.label.lastName')"
          icon="user"
          :has-data="typeof currentUserExpress.lastName === 'string'"
          :data="currentUserExpress.lastName"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('lastName', 'text', 'input', true)"
        />
        <screen-block
          :title="$t('userexpress.id.general.user.label.email')"
          icon="mail"
          :has-data="typeof currentUserExpress.email === 'string'"
          :data="currentUserExpress.email"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('email', 'text', 'input', true)"
        />
        <screen-block
          :title="$t('userexpress.id.general.user.label.locations')"
          icon="business"
          :has-data="hasUserExpressLocations"
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="!userExpressHasCampaign"
          @click="
            editLocations('locations', 'text', 'list', true, null, sortBy(locations, ['group', 'name']), {
              label: 'name',
              trackBy: 'id',
            })
          "
        >
          <template v-slot:data>
            <div class="userexpressid-general__locations">
              <span
                class="userexpressid-general__locations__item"
                v-for="(location, idx) in currentUserExpress.locations"
                :key="idx"
              >
                {{ location.group ? `[${location.group}]` : '' }} {{ location.name }}
              </span>
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('userexpress.id.general.user.label.status')"
          icon="user"
          align="center"
          :has-data="true"
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:data>
            <div>
              <template v-if="currentUserExpress.status === '1'">
                {{ $t('userexpress.id.general.user.active') }}
              </template>
              <template v-else>
                {{ $t('userexpress.id.general.user.inactive') }}
              </template>
            </div>
          </template>
          <template v-slot:right>
            <ui-switch
              id="active-location"
              :standalone="true"
              :value="userExpressStatus"
              :input-value="userExpressStatus"
              @input="updateUserExpressStatus"
            ></ui-switch>
          </template>
        </screen-block>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import sortBy from 'lodash/sortBy'
import UiSwitch from '@/components/UI/Switch.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ScreenNotification from '@/components/Screen/Notification.vue'

export default {
  name: 'UserExpressIdGeneral',
  components: {
    UiSwitch,
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    ScreenNotification,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentUserExpress: {
      type: Object,
      required: true,
      default: () => {},
    },
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy,
    }
  },
  computed: {
    hasUserExpressLocations() {
      return this.currentUserExpress.locations?.length > 0
    },
    userExpressStatus() {
      return this.currentUserExpress.status === '1' ? true : false
    },
    userExpressHasCampaign() {
      return this.currentUserExpress.campaignActive
    },
  },
  methods: {
    editSimpleField(objKey, type, mode, required = false, number = null, options = [], dropdown = {}) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
          dropdown,
        },
      })
    },
    editLocations(objKey, type, mode, required = false, number = null, options = [], dropdown = {}) {
      this.$emit('editLocations', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
          dropdown,
        },
      })
    },
    updateUserExpressStatus(status) {
      this.$emit('updateUserExpressStatus', { status: status === true ? '1' : '0' })
    },
  },
}
</script>

<style lang="scss" scoped>
.userexpressid-general {
  &__locations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;

    &__item {
      border-radius: 50px;
      background-color: map-get($generic-color-variants, 'data');
      padding: 2px 8px;
      color: $white;
      font-size: $font-size-sm;
    }
  }
}
</style>
