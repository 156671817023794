<template>
  <div class="userexpress-id" @scroll.passive="onScroll($event.srcElement, tabsElement)">
    <screen-header
      class="userexpress-id__header"
      :title="`${currentUserExpress.firstName} ${currentUserExpress.lastName}`"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading"
      v-if="!hasError"
    >
    </screen-header>
    <screen-error v-if="hasError" :route="{ name: 'UserExpress' }" :label="$t('userexpress.id.error.button.back')" />
    <screen-container direction="column" v-else>
      <!-- General Section -->
      <user-express-id-general
        :is-loading="isLoading"
        :current-user-express="currentUserExpress"
        :locations="locations"
        @editSimpleField="editSimpleField"
        @editLocations="editLocations"
        @updateUserExpressStatus="updateUserExpressStatus"
      />
    </screen-container>
    <modal-simple-field
      v-if="isEditing && editingModal === 'field'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      @save="saveSimpleField"
      @closed="closedModal"
      @search="searchLocations"
    />
    <modal-user-express-location
      v-if="isEditing && editingModal === 'location'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      @save="saveSimpleField"
      @closed="closedModal"
      @search="searchLocations"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onScroll } from '@/mixins/scroll.mixin'
import { notif } from '@/mixins/notification.mixin'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenError from '@/components/Screen/Error.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import ModalUserExpressLocation from '@/components/Modal/UserExpressLocation.vue'
import UserExpressIdGeneral from '@/components/UserExpressId/General.vue'

export default {
  name: 'UserExpressIdUser',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenError,
    ModalSimpleField,
    ModalUserExpressLocation,
    UserExpressIdGeneral,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      isEditing: false,
      editingModal: '',
      modal: {
        objKey: null,
        data: null,
        label: null,
        parameters: {
          type: 'text',
          mode: 'input',
          required: false,
          number: null,
        },
      },
      hasError: false,
      tabsElement: null,
    }
  },
  async created() {
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('general')
    }
    this.isLoading = true
    try {
      this.getCurrentUserExpress({ userExpressId: this.$route.params.id })
      this.getLocations({ tableParams: { mode: 'userexpress', ue: this.$route.params.id } })
    } catch (err) {
      this.$log.error(err)
      this.hasError = true
    } finally {
      this.isLoading = false
    }
  },
  computed: {
    ...mapState({
      currentUserExpress: state => state.userexpress.currentUserExpress,
      locations: state => state.location.locations,
    }),
    currentTab() {
      return this.$route.params.currentTab
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('userexpress.list.breadcrumb'),
          route: {
            name: 'UserExpress',
            params: {
              currentTab: 'user',
            },
          },
        },
        {
          label: `${this.currentUserExpress.firstName} ${this.currentUserExpress.lastName}`,
          route: {
            name: 'UserExpressId',
            params: {
              id: this.currentUserExpress.id,
              clientId: this.currentUserExpress.clientId,
            },
          },
        },
      ]
    },
    modalTitle() {
      return this.$t('common.modal.title.userexpress', {
        userexpress: `${this.currentUserExpress.firstName} ${this.currentUserExpress.lastName}`,
      })
    },
  },
  methods: {
    ...mapActions({
      getCurrentUserExpress: 'userexpress/getCurrentUserExpress',
      updateUserExpress: 'userexpress/updateUserExpress',
      getLocations: 'location/getLocations',
    }),
    searchLocations(e) {
      this.search = e
      this.getLocations({
        tableParams: { mode: 'userexpress', ue: this.currentUserExpress.id },
        search: this.search,
      }).then(() => {
        this.modal.parameters = {
          ...this.modal.parameters,
          options: this.locations,
        }
      })
    },
    editSimpleField(payload) {
      this.modal.objKey = payload.objKey
      this.modal.label = this.$t(`userexpress.id.modal.${payload.objKey}`)
      this.modal.data = this.currentUserExpress[payload.objKey]
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'field'
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    editLocations(payload) {
      this.modal.objKey = payload.objKey
      this.modal.label = this.$t(`userexpress.id.modal.${payload.objKey}`)
      this.modal.data = this.currentUserExpress[payload.objKey]
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'location'
      this.$nextTick(() => {
        this.$modal.show('modal-user-express-location')
      })
    },
    async updateUserExpressStatus(payload) {
      try {
        await this.updateUserExpress(payload)
        this.notificationSuccess(
          'userexpress',
          `${this.currentUserExpress.firstName} ${this.currentUserExpress.lastName}`
        )
      } catch (err) {
        this.$log.error(err)
        this.notificationError()
      }
    },
    saveSimpleField(params) {
      this._createUserExpressUpdate(async () => {
        await this.updateUserExpress(params.objKey)
      }, ['modal-simple-field'])
    },
    async _createUserExpressUpdate(updateFn, modaleNames = []) {
      this.isUpdating = true
      try {
        await updateFn()
        this.notificationSuccess(
          'userexpress',
          `${this.currentUserExpress.firstName} ${this.currentUserExpress.lastName}`
        )
      } catch (err) {
        this.$log.error(err)
        this.notificationError()
      } finally {
        ;[].concat(modaleNames).forEach(name => this.$modal.hide(name))
        this.closedModal()
      }
    },
    closedModal() {
      this.isEditing = false
      this.isUpdating = false
      this.editingModal = ''
    },
    setCurrentTab(tab) {
      this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
    },
  },
}
</script>

<style lang="scss">
.userexpress-id {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }
}
</style>
