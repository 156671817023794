<template>
  <modal
    name="modal-simple-field"
    :title="title"
    :has-apply="true"
    :apply-label="applyLabel"
    :is-updating="isUpdating"
    @save="save"
    @closed="closed"
  >
    <template v-slot:container>
      <template v-if="description">{{ description }}</template>
      <ui-input
        v-model.trim="modelData"
        :label="label"
        id="modal-simple-field-input"
        :error="$v.modelData.$error"
        :type="parameters.type"
        v-if="parameters.mode === 'input'"
        @enter="save"
      >
        <template v-if="$v.modelData.$error && !$v.modelData.required">
          {{ $t('errors.required') }}
        </template>
        <template v-if="$v.modelData.$error && !$v.modelData.email">
          {{ $t('errors.email') }}
        </template>
        <template v-if="$v.modelData.$error && !$v.modelData.tag">
          {{ $t('errors.tag') }}
        </template>
        <template v-if="$v.modelData.$error && !$v.modelData.url">
          {{ $t('errors.url') }}
        </template>
      </ui-input>
      <ui-textarea
        v-model.trim="modelData"
        :label="label"
        id="modal-simple-field-textarea"
        :error="$v.modelData.$error"
        v-else-if="parameters.mode === 'textarea'"
      >
        <template v-if="$v.modelData.$error && !$v.modelData.required">
          {{ $t('errors.required') }}
        </template>
      </ui-textarea>
      <div class="modal-simple-field__number" v-else-if="parameters.mode === 'number'">
        <div class="modal-simple-field__number__label modal-simple-field__number__label--full">
          {{ label }}
        </div>
        <vue-slider
          class="modal-simple-field__number__slider"
          v-model="modelData"
          tooltip="always"
          :min="parameters.number.min"
          :max="parameters.number.max"
          :clickable="true"
          :dotSize="24"
          :interval="parameters.number.step"
          :enable-cross="false"
          :rail-style="{
            backgroundColor: '#ddd',
            height: '6px',
          }"
          :dot-style="{
            backgroundColor: '#4e5cec',
            border: '2px solid #fff',
            boxShadow: 'none',
          }"
          :process-style="{ backgroundColor: '#4e5cec' }"
          :tooltip-style="{
            backgroundColor: '#4e5cec',
            borderColor: '#4e5cec',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            padding: '4px 8px',
          }"
          :marks="true"
          :adsorb="true"
        ></vue-slider>
      </div>
      <ui-dropdown
        v-else-if="parameters.mode === 'choice'"
        id="modal-simple-field-dropdown"
        v-model="modelData"
        :options="parameters.options"
        :placeholder="label.placeholder"
        :dropdown-label="label.dropdown"
        :label="parameters.dropdown && parameters.dropdown.label ? parameters.dropdown.label : null"
        :track-by="parameters.dropdown && parameters.dropdown.trackBy ? parameters.dropdown.trackBy : null"
        :show-label="true"
        :no-absolute="true"
      />
      <ui-tag
        v-else-if="parameters.mode === 'list'"
        v-model="modelData"
        id="modal-simple-field-tag"
        :placeholder="label.placeholder || ''"
        :tag-label="label.tag"
        :show-label="true"
        :error="$v.modelData.$error"
        :label="parameters.dropdown && parameters.dropdown.label ? parameters.dropdown.label : null"
        :track-by="parameters.dropdown && parameters.dropdown.trackBy ? parameters.dropdown.trackBy : null"
        :options="tagOptions"
        :no-absolute="true"
        :custom-tag="parameters.dropdown ? parameters.dropdown.customTag : false"
        :is-required="parameters.required"
        :taggable="parameters.taggable"
        :searchable="searchable"
        @tag="addTag"
        @input="setTags"
        @search="onSearch"
      >
        <template v-slot:helper v-if="$v.modelData.$error && !$v.modelData.required">
          {{ $t('errors.required') }}
        </template>
        <template v-slot:helper v-else-if="$v.modelData.$error">
          {{ $t('errors.email') }}
        </template>

        <template v-slot:tag="{ tag }" v-if="dropdownHaveCustomLabel">
          <span class="ui-tag__multiselect__tag__label">{{ customLabel(tag, parameters.dropdown.customLabel) }}</span>
        </template>
        <template v-slot:option="{ option }" v-if="dropdownHaveCustomLabel">
          <span v-if="option.isTag">{{ option.label }}</span>
          <span v-else>{{ customLabel(option, parameters.dropdown.customLabel) }}</span>
        </template>
      </ui-tag>
      <ui-date-picker
        v-else-if="parameters.mode === 'datepicker'"
        v-model="formattedDate"
        :locale="$i18n.locale"
        id="modal-simple-field-date-picker"
        :label="label"
      />
    </template>
  </modal>
</template>

<script>
import UiInput from '@/components/UI/Input.vue'
import UiTextarea from '@/components/UI/Textarea.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiTag from '@/components/UI/Tag.vue'
import UiDatePicker from '@/components/UI/DatePicker.vue'
import VueSlider from 'vue-slider-component'
import { required, email, url } from 'vuelidate/lib/validators'
import { tag } from '@/validators/tag.validator'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'ModalSimpleField',
  components: {
    UiInput,
    UiTextarea,
    UiDropdown,
    UiTag,
    UiDatePicker,
    VueSlider,
  },
  props: {
    applyLabel: {
      type: String,
      required: false,
      default: null,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Object],
      required: true,
    },
    objKey: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: [String, Object, Array, Number],
      required: false,
      default: '',
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        type: 'text',
        mode: 'input',
        required: false,
        options: [],
        listKey: '',
        number: {
          min: 0,
          max: 1,
          step: 1,
        },
        dropdown: {
          label: '',
          trackBy: '',
        },
      }),
    },
  },
  data() {
    return {
      modelData: '',
    }
  },
  mounted() {
    if (this.parameters.listKey) {
      this.modelData = this.data ? this.data.map(data => data[this.parameters.listKey]) : []
    } else if (typeof this.data === 'string' || typeof this.data === 'number') {
      this.modelData = this.data
    } else if (Array.isArray(this.data) || this.parameters.mode === 'list') {
      this.modelData = this.data ? [...this.data] : []
    } else if (
      typeof this.data === 'object' &&
      this.parameters.dropdown?.label?.length > 0 &&
      this.parameters.dropdown?.trackBy?.length > 0
    ) {
      this.modelData = Object.assign({}, this.data)
    } else if (typeof this.data === 'object' && this.data !== null) {
      this.modelData = JSON.stringify(this.data)
    }
  },
  computed: {
    formattedDate: {
      get() {
        return new Date(this.modelData)
      },
      set(date) {
        this.modelData = formatedDate(date, 'YYYY-MM-DD', this.$i18n.locale)
      },
    },
    tagOptions() {
      if (this.parameters.options?.length > 0) {
        return this.parameters.options
      }
      return []
    },
    dropdownHaveCustomLabel() {
      return !!this.parameters?.dropdown?.customLabel
    },
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.parameters.listKey) {
          this.$emit('save', {
            objKey: { [this.objKey]: this.modelData.map(data => ({ [this.parameters.listKey]: data })) },
          })
        } else {
          if (this.objKey === 'locationsMapping') {
            this.$emit('saveMapping', {
              objKey: { [this.objKey]: this.modelData },
            })
          } else {
            this.$emit('save', {
              objKey: { [this.objKey]: this.parameters.type === 'json' ? JSON.parse(this.modelData) : this.modelData },
            })
          }
        }
        this.$v.$reset()
      }
    },
    closed() {
      this.$emit('closed')
    },
    addTag(tag) {
      if (this.tagOptions.length === 0) {
        this.modelData.push(tag)
      }
    },
    setTags(tags) {
      this.$emit('tagsUpdate', tags)
      if (this.parameters.dropdown?.customTag) {
        this.modelData = tags.map(currentTags => currentTags.tag)
      } else {
        this.modelData = tags
      }
    },
    onSearch(value) {
      this.$emit('search', value)
    },
    customLabel(option, customLabel) {
      const labels = customLabel.split(' ')
      let string = ''
      labels.forEach(label => {
        string += `${option[label]} `
      })
      return string.trim()
    },
  },
  validations() {
    return {
      modelData: {
        required: this.parameters.required ? required : false,
        email: this.parameters.mode !== 'list' && this.parameters.type === 'email' ? email : false,
        url: this.parameters.mode !== 'list' && this.parameters.type === 'url' ? url : false,
        tag: this.parameters.mode !== 'list' && this.parameters.type === 'tag' && tag ? tag : false,
        $each: {
          email: this.parameters.mode === 'list' && this.parameters.type === 'email' ? email : false,
        },
      },
    }
  },
  beforeDestroy() {
    this.$emit('tagsUpdate', [])
  },
}
</script>

<style lang="scss" scoped>
.modal-simple-field {
  &__number {
    padding-bottom: 20px;

    &__label {
      @include input-label;
    }
  }
}
</style>
