import { scroll } from '@/utils/scroll.util'
import { isMobile, isTablet, isDesktop, isLargeScreen } from '@/utils/navigator.util'

export const onScroll = {
  data() {
    return {
      viewScrollTop: 0,
      elementOffsetTop: 0,
      scrollDirection: '',
      elementFixed: false,
      isMobile: isMobile(),
      isTablet: isTablet(),
      isDesktop: isDesktop(),
      isLargeScreen: isLargeScreen(),
      canRefreshData: 0,
    }
  },
  methods: {
    onScroll(view, element, distance = 0) {
      const scrolling = scroll(view, element, this.viewScrollTop)
      this.viewScrollTop = scrolling.currentScrollTop
      this.elementOffsetTop = scrolling.currentOffsetTop
      this.scrollDirection = scrolling.scrollDirection

      if (scrolling.offsetHeight + this.viewScrollTop + distance >= scrolling.scrollHeight) {
        this.canRefreshData = true
      } else {
        this.canRefreshData = false
      }

      if (this.elementOffsetTop <= 62) {
        this.elementFixed = true
      } else {
        this.elementFixed = false
      }
    },
  },
}
