<template>
  <div
    class="screen-block"
    :class="[
      `screen-block--${ratio}`,
      { 'screen-block--is-editable': isEditable && isHover, 'screen-block--align-center': align === 'center' },
    ]"
    :style="style"
    @click="isEditable ? onClick() : false"
  >
    <slot name="icon">
      <skeleton-icon v-if="isLoading" margin-right="16px" />
      <div class="screen-block__icon backoffice-icons" :style="iconStyle" v-else-if="!isLoading && icon">
        {{ icon }}
      </div>
    </slot>
    <div class="screen-block__content">
      <skeleton-line height="18px" v-if="isLoading" />
      <div class="screen-block__content__title" v-else>
        <span v-if="isRequired && isRTL">*</span>
        {{ title }}
        <span v-if="isRequired && !isRTL">*</span>
        <div v-if="checkLink && !isValideLink" class="screen-block__content__title__icon backoffice-icons">warning</div>
        <ui-helper :helper="helper"></ui-helper>
      </div>
      <div class="screen-block__content__data" :class="{ 'screen-block__content__data--one-line': isOneLine }">
        <skeleton-line height="18px" v-if="isLoading" margin-top="2px" width="50%" />
        <slot name="data" v-else-if="hasData">
          <div class="screen-block__content__data__text" v-if="!isImage">
            <div class="screen-block__content__data__text__content" v-html="data"></div>
            <ui-button
              v-if="isLink"
              class="screen-block__content__data__text__link"
              :label="$t('common.button.externalLink')"
              button="primary"
              icon="external_link"
              :icon-only="true"
              :variant="productLine"
              :is-link="true"
              :href="formatLink(data)"
              v-tooltip="{
                placement: 'top',
                trigger: 'hover',
                content: $t('common.button.externalLink'),
                offset: 3,
              }"
              @click.native.stop
            />
          </div>
          <div class="screen-block__content__data__img" v-else>
            <img :src="data" class="screen-block__content__data__img__file" />
            <ui-button
              class="screen-block__content__data__img__copy"
              :label="$t('common.button.copyImg')"
              button="primary"
              icon="copy"
              :icon-only="true"
              :variant="productLine"
              v-tooltip="{
                placement: 'top',
                trigger: 'hover',
                content: $t('common.button.copyImg'),
                offset: 3,
              }"
              @click.stop="copyLink(data)"
            />
          </div>
        </slot>
        <em v-else v-html="$t('common.label.noData')"> </em>
      </div>
    </div>
    <slot name="right"></slot>
  </div>
</template>

<script>
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import UiButton from '@/components/UI/Button.vue'
import UiHelper from '@/components/UI/Helper.vue'
import { productColor } from '@/config/productColor.config'
import { copyToClipboard } from '@/utils/extractor.util'
import { notification } from '@/utils/notification.util'
import { isRTL } from '@/utils/i18n.util'

export default {
  name: 'ScreenBlock',
  components: {
    SkeletonLine,
    SkeletonIcon,
    UiButton,
    UiHelper,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: '',
    },
    iconStyle: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    helper: {
      type: String,
      required: false,
      default: '',
    },
    hasData: {
      type: Boolean,
      required: true,
    },
    data: {
      type: [String, Number, Array, Boolean, Object],
      required: false,
    },
    ratio: {
      type: String,
      required: false,
      default: '',
    },
    align: {
      type: String,
      required: false,
      default: '',
    },
    isOneLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHover: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isRTL() {
      return isRTL(this.$i18n.locale)
    },
    style() {
      return {
        '--product-color': productColor[this.productLine],
      }
    },
    productLine() {
      return this.$route.meta.productLine || 'none'
    },
    isValideLink() {
      try {
        return new URL(this.data).protocol === 'https:'
      } catch {
        return false
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
    copyLink(link) {
      copyToClipboard(link)
      notification({
        text: this.$t('common.notification.copyImg'),
        type: 'success',
      })
    },
    formatLink(data) {
      var regex = /https?:\/\/(www\.)?/gi
      if (!regex.test(data)) {
        return `https://${data}`
      }
      return data
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-block {
  display: flex;
  border-radius: $radius-default;
  width: 100%;

  &--1-1,
  &--1-2,
  &--1-3,
  &--2-3 {
    padding: $gutter-mobile / 2;

    @media (min-width: $screen-sm) {
      padding: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      padding: $gutter-desktop / 2;
    }
  }

  &--1-2 {
    @media (min-width: $screen-sm) {
      width: calc(100% / 2);
    }
  }

  &--1-3 {
    @media (min-width: $screen-sm) {
      width: calc(100% / 2);
    }

    @media (min-width: $screen-md) {
      width: calc(100% / 3);
    }
  }

  &--2-3 {
    @media (min-width: $screen-sm) {
      width: calc(100% / 2);
    }

    @media (min-width: $screen-md) {
      width: calc(100% * (2 / 3));
    }
  }

  &--align-center {
    align-items: center;
  }

  &--is-editable {
    &:hover {
      background-color: var(--bg-color-hover);
      cursor: pointer;
    }
  }

  &__icon {
    margin-right: $gutter-mobile;
    color: var(--product-color);
  }

  &__content {
    flex: 1;
    overflow: hidden;

    &__title {
      display: flex;
      flex-direction: row;
      font-weight: 500;

      &__icon {
        margin-left: 3px;
        color: $orange-tree-poppy;
        font-size: $font-size-lg;
      }
    }

    &__data {
      margin-top: 2px;
      color: var(--text-color-alt);

      &--one-line {
        @include text-ellipsis(1, 14px, 2px);
      }

      &__text {
        display: flex;
        align-items: center;

        &__content {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &__link {
          margin-left: $gutter-mobile;
        }
      }

      &__img {
        display: flex;
        align-items: center;

        &__copy {
          margin-left: $gutter-mobile;
        }

        &__file {
          max-width: 240px;
          max-height: 100px;

          @media (min-width: $screen-sm) {
            max-width: 200px;
          }

          @media (min-width: $screen-md) {
            max-width: 170px;
          }

          @media (min-width: $screen-lg) {
            max-width: 230px;
          }

          @media (min-width: $screen-xl) {
            max-width: 240px;
          }
        }
      }
    }
  }
}
</style>
