<template>
  <div class="screen-notification" :class="`screen-notification--${ratio}`" :style="`background-color: ${bgColor}`">
    <div
      class="screen-notification__body"
      :class="`screen-notification__body--${align}`"
      :style="`color: ${textColor}`"
    >
      <span class="screen-notification__body__icon backoffice-icons">{{ icon }}</span>
      <span class="screen-notification__body__description">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScreenNotifiction',
  props: {
    bgColor: {
      type: String,
      required: false,
      default: '#f7941d',
    },
    textColor: {
      type: String,
      required: false,
      default: 'var(--text-color-reverse)',
    },
    description: {
      type: String,
      required: true,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: 'priority',
    },
    align: {
      type: String,
      required: false,
      default: 'left',
    },
    ratio: {
      type: String,
      required: false,
      default: '1-1',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-notification {
  margin: 0 (-$gutter-mobile);
  border-bottom: 1px solid var(--border-color);

  @media (min-width: $screen-sm) {
    margin: $gutter-tablet / 2;
    margin-bottom: 0;
    border: 0;
    border-radius: $radius-default;
    box-shadow: 0 0 15px var(--box-shadow-color);
    width: 100%;
  }

  @media (min-width: $screen-xl) {
    margin: $gutter-desktop / 2;
    margin-bottom: 0;
  }

  &--1-2 {
    @media (min-width: $screen-md) {
      width: calc(100% / 2 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 2 - #{$gutter-desktop});
    }
  }

  &--1-3 {
    @media (min-width: $screen-md) {
      width: calc(100% / 3 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 3 - #{$gutter-desktop});
    }
  }

  &--1-4 {
    @media (min-width: $screen-md) {
      width: calc(100% / 4 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 4 - #{$gutter-desktop});
    }
  }

  &--2-3 {
    @media (min-width: $screen-md) {
      width: calc(100% * (2 / 3) - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% * (2 / 3) - #{$gutter-desktop});
    }
  }

  &--3-4 {
    @media (min-width: $screen-md) {
      width: calc(100% * (3 / 4) - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% * (3 / 4) - #{$gutter-desktop});
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $gutter-mobile/2 $gutter-mobile;
    height: 100%;

    @media (min-width: $screen-sm) {
      padding: $gutter-tablet/2 $gutter-tablet;
    }

    @media (min-width: $screen-xl) {
      padding: $gutter-desktop/2 $gutter-desktop;
    }

    &__icon {
      margin-right: $gutter-mobile;
      font-size: $font-size-lg;
    }

    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }
  }
}
</style>
